import { useTranslation } from "next-i18next";
import React, { ReactNode } from "react";
import { Snippet, useInfiniteHits } from "react-instantsearch";
import Button from "../../ui/Button";
import ProgressIndicator from "../../ui/ProgressIndicator";
import FilterCard from "../../ui/FilterCard";
import SearchHits from "../../ui/SearchHits";
import { resolveSlug } from "../../lib/utils/resolveSlug";
import ContentGrid from "../../ui/ContentGrid";
import { StorySortMethod } from "./StorySort";
import { ImageVaultFieldType } from "../../lib/storyblok/types/fieldtypes/imagevaultFieldtype";
import Image from "next/image";
import ImageVaultImage from "../ImageVaultImage";
import fallbackImage from "../../public/images/tobii-seo-fallback.jpg";
import { imageSizes } from "../../lib/utils/imageSizes";
import { PastEvent } from "../../ui/Button/PastEvent";

export type StoryHit = {
  date?: string;
  startDate?: string;
  objectID: string;
  fullSlug: string;
  storyType: string;
  title: string;
  publishedAt?: string;
  metaTitle?: string;
  metaDescription?: string;
  image?: ImageVaultFieldType;
  content?: string;
  features?: Record<string, unknown>[];
  products?: string[];
  types?: string[];
  categories?: string[];
  location?: string;
  author?: string;
  readTime?: string;
  children?: ReactNode;
  availability?: string;
  __position: number;
  __queryID?: string;
};

type Props = {
  sortMethod: StorySortMethod;
  renderImages: boolean;
  renderDate: boolean;
  pageType?: string;
};

const getBodyAttribute = (hit: StoryHit) =>
  typeof hit.metaDescription === "string" &&
  hit.metaDescription.trim().length > 0
    ? "metaDescription"
    : "content";

const sortHits = (
  sortMethod: StorySortMethod,
  hits: StoryHit[]
): StoryHit[] => {
  const localHits = [...hits];
  if (sortMethod === "Ascending") {
    return localHits.sort((a, b) => a.title.localeCompare(b.title));
  }
  if (sortMethod === "Descending") {
    return localHits.sort((a, b) => -1 * a.title.localeCompare(b.title));
  }
  return localHits;
};

const InfiniteStoryHits = ({
  sortMethod,
  renderImages,
  renderDate,
  pageType,
}: Props) => {
  const { t } = useTranslation(["searchpage", "tags"]);

  const { hits, results, showMore } = useInfiniteHits<StoryHit>();
  const total = results?.nbHits ?? 0;
  const current = hits.length;

  const showPagination = current < total;
  const totalLabel = t("number-of-hits", { count: total });
  const progressLabel = `${t("showing", { amount: current })}/${totalLabel}`;

  const isExpired = (hit: StoryHit) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return hit.date && new Date(hit.date) < today;
  };

  const sortedHits = sortHits(sortMethod, hits);
  const getImage = (hit: StoryHit) => {
    if (!renderImages) return;
    const expired = isExpired(hit);

    return hit.image ? (
      <div>
        <ImageVaultImage
          image={hit.image}
          sizes={imageSizes("100vw", { laptop: "20vw" })}
          contain={false}
          layout="fill"
          style={{
            opacity: expired && pageType === "eventFinderPage" ? 0.2 : 1,
          }}
          alt={hit.title}
        />
        {expired && pageType === "eventFinderPage" && (
          <div
            style={{
              position: "absolute",
              bottom: "14px",
              right: "18px",
            }}
          >
            <PastEvent variant={""}>{t("past-event")}</PastEvent>
          </div>
        )}
      </div>
    ) : (
      <Image
        src={fallbackImage}
        sizes={"720px"}
        objectFit="contain"
        layout="fill"
        alt={hit.title}
      />
    );
  };

  // For Press Releases we'd like to have two column layout
  const gridType =
    pageType === "pressReleaseFinderPage" ? "contentGridAlt" : "";

  return (
    <>
      <ContentGrid spacing="noTop" container="fluid" gridType={gridType}>
        {sortedHits.map((hit) => {
          const slug = resolveSlug(hit.fullSlug);
          const expired = isExpired(hit);
          return (
            <>
              <a title={hit.title} href={slug}>
                <FilterCard
                  author={hit.author && hit.author}
                  readTime={hit.readTime && hit.readTime}
                  categories={hit.categories && hit.categories}
                  key={hit.objectID}
                  date={renderDate ? hit.date : ""}
                  startDate={renderDate && hit.startDate ? hit.startDate : ""}
                  location={hit.availability && hit.availability}
                  label={
                    expired && (
                      <PastEvent variant={expired ? "investor" : ""}>
                        {t("past-event")}
                      </PastEvent>
                    )
                  }
                  type={
                    hit.types && hit.types.length
                      ? t(hit.types[0], { ns: "tags" })
                      : ""
                  }
                  image={
                    getImage(hit) ? (
                      <a title={hit.title} href={slug}>
                        {getImage(hit)}
                      </a>
                    ) : undefined
                  }
                  title={
                    <a title={hit.title} href={slug}>
                      <Snippet hit={hit} attribute={"title"} />
                    </a>
                  }
                  link={
                    // Comment: We are not representing this part in the front end
                    <Button
                      variant="text"
                      title={hit.title}
                      href={slug}
                      icon="caret-right"
                    >
                      {t("learn-more")}
                    </Button>
                  }
                  pageType={pageType}
                >
                  <p>
                    <Snippet hit={hit} attribute={getBodyAttribute(hit)} />
                  </p>
                </FilterCard>
              </a>
            </>
          );
        })}
      </ContentGrid>
      {showPagination && (
        <SearchHits.Pagination>
          <ProgressIndicator current={current} total={total}>
            {progressLabel}
          </ProgressIndicator>
          <Button onClick={showMore}>{t("load-more")}</Button>
        </SearchHits.Pagination>
      )}
    </>
  );
};

export default InfiniteStoryHits;
