import React, { useMemo, useState, useEffect, useRef } from "react";
import cn from "classnames";
import s from "../Hero.module.scss";

type Props = {
  videoUrl: string | undefined;
  delay?: boolean;
  noOverlay?: boolean;
  parallax?: boolean;
  maxPlays?: number;
};

const VideoPlayer = ({
  videoUrl,
  delay,
  noOverlay,
  parallax,
  maxPlays = 3,
}: Props) => {
  const [loaded, setLoaded] = useState(false);
  const [playCount, setPlayCount] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);

  const opacity = useMemo(() => {
    return loaded ? 0.7 : 1;
  }, [loaded]);

  useEffect(() => {
    setPlayCount(0);
    setLoaded(false);
  }, [videoUrl]);

  useEffect(() => {
    if (!videoRef.current) return;

    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        const video = videoRef.current;
        if (!video) return;

        if (entry.isIntersecting && playCount < maxPlays) {
          video.muted = true;
          video
            .play()
            .then(() => console.log("Video started playing"))
            .catch((e) => {
              if (e.name === "NotAllowedError") {
                video
                  .play()
                  // eslint-disable-next-line
                  .catch((e) => console.warn("Video playback failed:", e));
                video.play();
                // eslint-disable-next-line
                console.warn(
                  "Autoplay not allowed. Video playback requires user interaction."
                );
              } else {
                // eslint-disable-next-line
                console.error("Unexpected video playback error:", e);
              }
            });
        } else {
          video.pause();
        }
      },
      { threshold: 0.5 }
    );

    observerRef.current.observe(videoRef.current);

    return () => {
      observerRef.current?.disconnect();
    };
  }, [playCount, maxPlays]);

  const handleVideoEnded = () => {
    if (!videoRef.current?.loop) {
      setPlayCount((prev) => prev + 1);
    }
  };

  const handlePlay = () => {
    setTimeout(
      () => {
        setLoaded(true);
      },
      delay ? 3000 : 0
    );
  };

  useEffect(() => {
    if (playCount >= maxPlays && videoRef.current) {
      videoRef.current.pause();
    }
  }, [playCount, maxPlays]);

  return (
    <>
      <video
        ref={videoRef}
        className={cn(s.videoPlayer, { [s.parallax]: parallax })}
        width="100%"
        height="100%"
        preload="auto"
        muted
        playsInline
        loop
        onPlay={handlePlay}
        onEnded={handleVideoEnded}
        src={videoUrl}
      />
      <div
        className={cn({
          [s.overlay]: !noOverlay,
        })}
        style={{ opacity }}
      />
    </>
  );
};

export default VideoPlayer;
