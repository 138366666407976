import React, { ReactNode, useEffect, useState } from "react";
import cn from "classnames";
import s from "./ContentGrid.module.scss";
import c from "../../styles/container.module.scss";
import Item from "./components/Item";
import Markdown from "../Markdown";
import List from "./components/List";
import ListItem from "./components/ListItem";
import Container from "./components/Container";
import useIsInViewport from "use-is-in-viewport";

type Props = {
  title?: string;
  description?: string;
  twoColumns?: boolean;
  noGap?: boolean;
  spacing?: string;
  isStartpage?: boolean;
  children: ReactNode;
  container?: "xWide" | "fluid";
  className?: string;
  gridType?: string;
};

const subComponents = { Item, List, ListItem, Container };
type SubComponents = typeof subComponents;

const ContentGrid: CWS<Props, SubComponents> = ({
  title,
  description,
  twoColumns,
  noGap,
  spacing,
  isStartpage,
  children,
  container = "xWide",
  className = "",
  gridType = "",
}) => {
  const [isInViewport, targetRef] = useIsInViewport({ threshold: 50 });
  const [hasBeenInViewport, setHasBeenInViewport] = useState(false);

  useEffect(() => {
    if (isInViewport && !hasBeenInViewport) {
      setTimeout(() => {
        setHasBeenInViewport(true);
      }, 1000);
    }
  }, [isInViewport, hasBeenInViewport]);

  return (
    <section
      className={cn(s.section, c[container], s[className], {
        [s.noTopSpacing]: spacing === "noTop",
        [s.noBottomSpacing]: spacing === "noBottom",
        [s.noSpacing]: spacing === "noSpacing",
      })}
    >
      {title && <h2>{title}</h2>}
      {description && (
        <div className={s.description}>
          <Markdown>{description}</Markdown>
        </div>
      )}
      <div
        ref={targetRef}
        className={cn(s.contentGrid, c[container], s[gridType], {
          [s.twoColumns]: twoColumns,
          [s.noGap]: noGap,
          [s.marginTop]: title || description,
          [s.startpage]: isStartpage,
          [s.inViewport]: isInViewport || hasBeenInViewport,
        })}
      >
        {children}
      </div>
    </section>
  );
};

ContentGrid.Item = Item;
ContentGrid.List = List;
ContentGrid.ListItem = ListItem;
ContentGrid.Container = Container;

export default ContentGrid;
