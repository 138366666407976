import { StoryblokStoryComponent } from "../lib/storyblok/types/StoryblokComponent";
import SimpleContentPage from "./PageTemplates/SimpleContentPage";
import EventPage from "./PageTemplates/EventPage";
import InvestorEventPage from "./PageTemplates/InvestorEventPage";
import ArticlePage from "./PageTemplates/ArticlePage";
import MainSearchPage from "./PageTemplates/MainSearchPage";
import ProductFinderPage from "./PageTemplates/ProductFinderPage";
import ResourceFinderPage from "./PageTemplates/ResourceFinderPage";
import PressreleaseFinderPage from "./PageTemplates/PressreleaseFinderPage";
import EventFinderPage from "./PageTemplates/EventFinderPage";
import ArticleFinderPage from "./PageTemplates/ArticleFinderPage";
import ArticleFinderPageV2 from "./PageTemplates/ArticleFinderPageV2";
import { ViewModel } from "../lib/viewModel/createViewModel";
import ResourcePage from "./PageTemplates/ResourcePage";
import StartPage from "./PageTemplates/StartPage";
import ImagevaultGalleryPage from "./PageTemplates/ImagevaultGalleryPage";
import PublicationPage from "./PageTemplates/PublicationPage";
import InvestorSearchPage from "./PageTemplates/InvestorSearchPage";

const NotCreated: StoryblokStoryComponent = ({ story }) => (
  <pre>
    The story component <strong>{story.content.component}</strong> has not been
    created yet.
  </pre>
);

const components: { [index: string]: StoryblokStoryComponent } = {
  productPage: SimpleContentPage,
  simpleContentPage: SimpleContentPage,
  landingPage: SimpleContentPage,
  solutionsPage: SimpleContentPage,
  mainEventPage: EventPage,
  eventPage: InvestorEventPage,
  articlePage: ArticlePage,
  searchPage: MainSearchPage,
  investorSearchPage: InvestorSearchPage,
  resourcePage: ResourcePage,
  productFinderPage: ProductFinderPage,
  resourceFinderPage: ResourceFinderPage,
  pressreleaseFinderPage: PressreleaseFinderPage,
  eventFinderPage: EventFinderPage,
  articleFinderPage: ArticleFinderPage,
  articleFinderPageV2: ArticleFinderPageV2,
  startPage: StartPage,
  imagevaultGalleryPage: ImagevaultGalleryPage,
  publicationPage: PublicationPage,
};

type Props = { viewModel: ViewModel };

export const DynamicStory = ({ viewModel }: Props) => {
  const Component = components[viewModel.story.content.component];

  if (Component === undefined) return <NotCreated {...viewModel} />;

  return <Component {...viewModel} />;
};
